import { makeApi } from '@zodios/core'
import { z } from 'zod'

export const Group = z.enum([
  'replay/club-admin',
  'replay/education',
  'replay/player',
  'replay/coach',
])
export const ClubUser = z.object({
  _id: z.string(),
  user_id: z.string(),
  club_id: z.string(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  group: Group,
})
export const ValidationError = z.object({
  loc: z.array(z.union([z.string(), z.number()])),
  msg: z.string(),
  type: z.string(),
})
export const HTTPValidationError = z
  .object({ detail: z.array(ValidationError) })
  .partial()
export const Language = z.enum(['de', 'en'])
export const UserCreate = z.object({
  email: z.string(),
  password: z.string().optional(),
  first_name: z.string(),
  last_name: z.string(),
  lang: Language.optional(),
  group: Group.optional(),
})
export const UserUpdate = z.object({ group: Group })
export const Club = z.object({ _id: z.string(), name: z.string() })
export const TestMatchCreate = z.object({
  team_home: z.string(),
  team_away: z.string(),
  recording_path: z.string(),
})
export const TestMatchAccess = z.object({ access_club_id: z.string() })
export const Plan = z.enum(['basic', 'live', 'demo', 'sharing'])
export const ClubCreate = z.object({
  teams: z.array(z.string()),
  test_matches: z.array(z.union([TestMatchCreate, TestMatchAccess])),
  users: z.array(UserCreate),
  plan: Plan.optional(),
  name: z.string(),
})
export const ClubResponse = z.object({
  club: Club,
  created_teams: z.array(z.string()),
  added_user_ids: z.array(z.string()),
  user_passwords: z.string(),
})
export const ClubUpdate = z.object({
  teams: z.array(z.string()),
  test_matches: z.array(z.union([TestMatchCreate, TestMatchAccess])),
  users: z.array(UserCreate),
  plan: Plan.optional(),
})
export const TeamWithClub = z.object({
  _id: z.string(),
  club: Club,
  name: z.string(),
})
export const ClubForStandaloneTeam = z.object({ name: z.string() })
export const StandaloneTeam = z.object({
  _id: z.string(),
  name: z.string(),
  club: ClubForStandaloneTeam,
  custom_team_from_club_id: z.string(),
})
export const MatchStatus = z.enum([
  'planned',
  'ready_for_recording',
  'recording',
  'recorded',
  'uploading',
  'uploaded',
  'post_processing',
  'finished',
  'error',
])
export const StatsStatus = z.enum([
  'pending',
  'processing',
  'review',
  'finalizing',
  'finished',
  'error',
])
export const StatsInfo = z.enum(['red_card'])
export const YoutubeLiveStream = z.object({
  broadcast_id: z.string(),
  stream_url: z.string(),
})
export const MatchWithImage = z.object({
  team_home: z.union([TeamWithClub, StandaloneTeam]),
  team_away: z.union([TeamWithClub, StandaloneTeam]),
  start_time: z.string(),
  recording_start_time: z.string().optional(),
  end_time: z.string(),
  duration: z.number().int().optional(),
  status: MatchStatus,
  recording: z.boolean(),
  recording_path: z.string(),
  recording_system: z.string().optional(),
  recording_system_id: z.string().optional(),
  recording_system_roi: z.array(z.array(z.unknown())).optional(),
  upload_url: z.string().optional(),
  upload_url_creation_time: z.string().optional(),
  uploaded: z.boolean(),
  upload_progress: z.number().optional(),
  upload_progress_tactical: z.number().optional(),
  upload_progress_panoramic: z.number().optional(),
  upload_progress_left: z.number().optional(),
  upload_progress_right: z.number().optional(),
  upload_progress_goal_left: z.number().optional(),
  upload_progress_goal_right: z.number().optional(),
  post_processing_trigger_time: z.number().optional(),
  post_processing_progress: z.number().optional(),
  kickoff_1st_half: z.number().int().optional(),
  kickoff_2nd_half: z.number().int().optional(),
  goals_home: z.array(z.number()).optional(),
  goals_away: z.array(z.number()).optional(),
  left_right_config_mix_up: z.boolean().optional(),
  left_ahead: z.number().int().optional(),
  stats_status: StatsStatus.optional(),
  stats_processing_progress: z.number().optional(),
  stats_error_msg: z.string().optional(),
  stats_info: StatsInfo.optional(),
  is_home: z.boolean().optional(),
  belongs_to: z.string(),
  has_access: z.array(z.string()),
  share_key: z.string(),
  archived: z.boolean().optional(),
  livestream_token: z.string().optional(),
  stream_left_token: z.string().optional(),
  stream_right_token: z.string().optional(),
  stream_goal_left_token: z.string().optional(),
  stream_goal_right_token: z.string().optional(),
  live: z.boolean().optional(),
  youtube_live_stream: YoutubeLiveStream.optional(),
  _id: z.string(),
  thumbnail: z.string().optional(),
  sprite: z.string().optional(),
})
export const MatchCreate = z.object({
  team_home: z.union([TeamWithClub, StandaloneTeam]),
  team_away: z.union([TeamWithClub, StandaloneTeam]),
  lang: z.string().optional().default('en'),
  timezone: z.string().optional().default('UTC'),
  is_home: z.boolean(),
  recording_system_id: z.string(),
  start_time: z.string(),
  end_time_calendar: z.string().optional(),
  recording_system_roi_id: z.string().optional(),
  shared_with_league_id: z.string().optional(),
  duration: z.number().int().optional().default(110),
  live: z.boolean().optional(),
  youtube_live: z.boolean().optional(),
})
export const Match = z.object({
  team_home: z.union([TeamWithClub, StandaloneTeam]),
  team_away: z.union([TeamWithClub, StandaloneTeam]),
  start_time: z.string(),
  recording_start_time: z.string().optional(),
  end_time: z.string(),
  duration: z.number().int().optional(),
  status: MatchStatus,
  recording: z.boolean(),
  recording_path: z.string(),
  recording_system: z.string().optional(),
  recording_system_id: z.string().optional(),
  recording_system_roi: z.array(z.array(z.unknown())).optional(),
  upload_url: z.string().optional(),
  upload_url_creation_time: z.string().optional(),
  uploaded: z.boolean(),
  upload_progress: z.number().optional(),
  upload_progress_tactical: z.number().optional(),
  upload_progress_panoramic: z.number().optional(),
  upload_progress_left: z.number().optional(),
  upload_progress_right: z.number().optional(),
  upload_progress_goal_left: z.number().optional(),
  upload_progress_goal_right: z.number().optional(),
  post_processing_trigger_time: z.number().optional(),
  post_processing_progress: z.number().optional(),
  kickoff_1st_half: z.number().int().optional(),
  kickoff_2nd_half: z.number().int().optional(),
  goals_home: z.array(z.number()).optional(),
  goals_away: z.array(z.number()).optional(),
  left_right_config_mix_up: z.boolean().optional(),
  left_ahead: z.number().int().optional(),
  stats_status: StatsStatus.optional(),
  stats_processing_progress: z.number().optional(),
  stats_error_msg: z.string().optional(),
  stats_info: StatsInfo.optional(),
  is_home: z.boolean().optional(),
  belongs_to: z.string(),
  has_access: z.array(z.string()),
  share_key: z.string(),
  archived: z.boolean().optional(),
  livestream_token: z.string().optional(),
  stream_left_token: z.string().optional(),
  stream_right_token: z.string().optional(),
  stream_goal_left_token: z.string().optional(),
  stream_goal_right_token: z.string().optional(),
  live: z.boolean().optional(),
  youtube_live_stream: YoutubeLiveStream.optional(),
  _id: z.string(),
})
export const MatchUpdate = z
  .object({
    kickoff_1st_half: z.number().int(),
    kickoff_2nd_half: z.number().int(),
    goals_home: z.array(z.number()),
    goals_away: z.array(z.number()),
  })
  .partial()
export const UrlCheckResponse = z.object({ video_url: z.string() })
export const LocalMatchCreate = z.object({
  team_home: z.union([TeamWithClub, StandaloneTeam]),
  team_away: z.union([TeamWithClub, StandaloneTeam]),
  start_time: z.string(),
  upload: z.boolean().optional(),
  local_upload: z.boolean().optional().default(true),
  video_url: z.string().optional(),
})
export const MatchUrls = z
  .object({
    panoramic_view: z.string(),
    tactical_view: z.string(),
    goal_left_view: z.string(),
    goal_right_view: z.string(),
    left_view: z.string(),
    right_view: z.string(),
  })
  .partial()
export const SharingModel = z.object({
  share_key: z.string(),
  shared_by_user: z.string(),
  shared_by_club: z.string(),
  shared_match: z.string(),
  match_id: z.string(),
  exp: z.string(),
  iat: z.string(),
  _id: z.string(),
})
export const StandaloneTeamCreate = z.object({
  name: z.string(),
  club: ClubForStandaloneTeam,
  custom_team_from_club_id: z.string(),
})
export const TeamWithClubCreate = z.object({ club: Club, name: z.string() })
export const BoxVersion = z.enum(['v1', 'v1.2', 'v2'])
export const DeviceState = z
  .object({
    heartbeat: z.string(),
    device_id: z.string(),
    left_camera: z.boolean(),
    right_camera: z.boolean(),
    cpu_temp: z.number(),
    gpu_temp: z.number(),
    ao_temp: z.number(),
    pll_temp: z.number(),
    thermal_temp: z.number(),
    fan_state: z.number(),
    disk_use: z.number(),
    internet_up: z.number(),
    internet_down: z.number(),
  })
  .partial()
export const RecordingSystemOutAdmin = z.object({
  _id: z.string(),
  club_id: z.string(),
  name: z.string(),
  fixed_mounted: z.boolean(),
  box_version: BoxVersion.optional().default('v1'),
  plannable: z.boolean().optional().default(true),
  device_id: z.string(),
  club_name: z.string().optional(),
  device_state: DeviceState.optional(),
})
export const RecordingSystemOut = z.object({
  _id: z.string(),
  club_id: z.string(),
  name: z.string(),
  fixed_mounted: z.boolean(),
  box_version: BoxVersion.optional().default('v1'),
  plannable: z.boolean().optional().default(true),
  device_id: z.string(),
  device_state: DeviceState.optional(),
})
export const CustomRoiMask = z.object({
  _id: z.string(),
  roi: z.array(z.array(z.unknown())),
  name: z.string(),
  recording_system_id: z.string(),
})
export const PitchImage = z.object({
  timestamp: z.string(),
  left: z.string(),
  right: z.string(),
})
export const EventType = z.object({
  name: z.string(),
  default_seconds_before: z.number().int(),
  default_seconds_after: z.number().int(),
  hotkey: z.string().optional(),
  color: z.string().optional(),
})
export const BoardEvent = z.object({
  event: EventType,
  x: z.number().int(),
  y: z.number().int(),
  width: z.number().int(),
  height: z.number().int(),
  color: z.string().optional(),
})
export const Board = z.object({
  name: z.string(),
  board_events: z.array(BoardEvent),
  user_id: z.string(),
  _id: z.string(),
})
export const BoardCreate = z.object({
  name: z.string(),
  board_events: z.array(BoardEvent),
})
export const Event = z.object({
  match_id: z.string(),
  ms_from_start: z.number().int(),
  type: EventType,
  seconds_before: z.number().int(),
  seconds_after: z.number().int(),
  note: z.string().optional(),
  belongs_to: z.string().optional(),
  color: z.string().optional(),
  _id: z.string(),
})
export const EventCreate = z.object({
  match_id: z.string(),
  ms_from_start: z.number().int(),
  type: EventType,
  seconds_before: z.number().int(),
  seconds_after: z.number().int(),
  note: z.string().optional(),
  color: z.string().optional(),
  browser_info: z.object({}).partial().optional(),
})
export const Playlist = z.object({
  pinned: z.boolean().optional(),
  name: z.string(),
  event_ids: z.array(z.string()),
  belongs_to_club: z.string(),
  _id: z.string(),
})
export const PlaylistCreate = z.object({ name: z.string() })
export const PlaylistView = z.object({
  _id: z.string(),
  pinned: z.boolean().optional(),
  name: z.string(),
  clip_count: z.number().int(),
  play_time: z.number().int(),
})
export const EventWithMatch = z.object({
  match_id: z.string(),
  ms_from_start: z.number().int(),
  type: EventType,
  seconds_before: z.number().int(),
  seconds_after: z.number().int(),
  note: z.string().optional(),
  belongs_to: z.string().optional(),
  color: z.string().optional(),
  _id: z.string(),
  match: Match,
})
export const PlaylistWithEvents = z.object({
  _id: z.string(),
  name: z.string(),
  events: z.array(EventWithMatch),
  belongs_to_club: z.string(),
})
export const PlaylistUpdate = z
  .object({
    name: z.string(),
    pinned: z.boolean(),
    event_ids: z.array(z.string()),
  })
  .partial()
export const PlaylistEvent = z.object({ event_id: z.string() })
export const ToolType = z.enum([
  'area',
  'area_ellipse',
  'pen',
  'highlight',
  'circle',
  'arrow_full',
  'arrow_wave',
  'arrow_dash',
  'text',
])
export const Color = z.enum([
  'red',
  'blue',
  'green',
  'yellow',
  'orange',
  'black',
  'white',
])
export const DrawingShape = z.object({
  type: ToolType,
  points: z.array(z.array(z.number())),
  color: Color,
  finished: z.boolean().optional(),
  text: z.string().optional(),
  rotation: z.number().optional(),
  scale: z.number().optional(),
})
export const DrawingCreate = z.object({
  event_id: z.string(),
  playlist_id: z.string(),
  at_ms: z.number().int(),
  shapes: z.array(DrawingShape),
})
export const Drawing = z.object({
  _id: z.string(),
  event_id: z.string(),
  playlist_id: z.string(),
  at_ms: z.number().int(),
  shapes: z.array(DrawingShape),
})
export const League = z.object({
  _id: z.string(),
  name: z.string(),
  teams: z.array(TeamWithClub),
  sharing_obligation: z.boolean().optional(),
})
export const TrackReview = z.object({
  id: z.number().int(),
  last_track_id: z.number().int().optional(),
  bounding_box: z.array(z.number()),
  confidence: z.number(),
})
export const ReviewSituation = z.object({
  _id: z.string(),
  match_id: z.string(),
  index: z.number().int(),
  frame: z.number().int(),
  team: z.number().int(),
  track_reviews: z.array(TrackReview),
})
export const TrackAssignment = z.object({
  track_id: z.number().int(),
  player_id: z.string(),
})
export const Assignments = z.object({
  _id: z.string(),
  match_id: z.string(),
  team_id: z.string(),
  team_no: z.number().int(),
  track_player_assignments: z.array(TrackAssignment),
})
export const AssignmentsCreate = z.object({
  match_id: z.string(),
  team_id: z.string(),
  team_no: z.number().int(),
  track_player_assignments: z.array(TrackAssignment),
})
export const KickoffTrackingResponse = z.object({
  reason: z.enum(['no_kickoff_times_set']),
})
export const TrackingUrlResponse = z
  .object({ tracking_url: z.string() })
  .partial()
export const Position = z.enum(['defense', 'midfield', 'striker', 'goalkeeper'])
export const Player = z.object({
  jersey_no: z.string().optional(),
  player_no: z.string().optional(),
  name: z.string(),
  position: Position,
  teams: z.array(TeamWithClub),
  belongs_to: z.array(z.string()),
  _id: z.string(),
})
export const HIR = z.object({
  start: z.number().int(),
  end: z.number().int().optional(),
  avg_speed: z.number().optional(),
  min_speed: z.number().optional(),
  max_speed: z.number().optional(),
  distance: z.number().optional(),
  pos: z.array(z.array(z.unknown())),
})
export const PlayerStats = z.object({
  _id: z.string(),
  team_id: z.string(),
  match_id: z.string(),
  player: Player,
  max_speed: z.number(),
  median_speed: z.number(),
  distance: z.number(),
  tracked_time: z.number().optional(),
  hirs: z.union([z.array(HIR), z.number()]),
  frames_covered: z.array(z.array(z.number())),
  heatmap: z.array(z.array(z.number())).optional(),
})
export const PlayerUpdate = z.object({
  jersey_no: z.string().optional(),
  player_no: z.string().optional(),
  name: z.string(),
  position: Position,
  teams: z.array(TeamWithClub),
  _id: z.string(),
})
export const PlayerCreate = z.object({
  jersey_no: z.string().optional(),
  player_no: z.string().optional(),
  name: z.string(),
  position: Position,
  teams: z.array(TeamWithClub),
})
export const ShareRequest = z.object({
  share_with: z.string(),
  team_ids: z.array(z.string()),
  date_from: z.string().optional().default('2024-01-01T00:00:00'),
  date_to: z.string().optional().default('2024-01-01T00:00:00'),
  with_events: z.boolean().optional(),
})
export const ReplayUserUpdate = z.object({
  shownIntroductionScreens: z.array(z.string()),
})
export const DownloadStatus = z.enum([
  'pending',
  'processing',
  'finished',
  'error',
])
export const DownloadWithLink = z.object({
  _id: z.string(),
  event_id: z.string().optional(),
  playlist_id: z.string(),
  name: z.string(),
  user_id: z.string(),
  hash: z.string(),
  created_at: z.string(),
  acknowledged_at: z.string().optional(),
  status: DownloadStatus,
  progress: z.number().optional(),
  error_msg: z.string().optional(),
  download_url: z.string().optional(),
})
export const DownloadCreate = z.object({
  event_id: z.string().optional(),
  playlist_id: z.string(),
  name: z.string(),
})
export const Download = z.object({
  _id: z.string(),
  event_id: z.string().optional(),
  playlist_id: z.string(),
  name: z.string(),
  user_id: z.string(),
  hash: z.string(),
  created_at: z.string(),
  acknowledged_at: z.string().optional(),
  status: DownloadStatus,
  progress: z.number().optional(),
  error_msg: z.string().optional(),
})
export const DownloadUpdate = z.object({ acknowledged: z.boolean() })
export const HIRPos = z.object({
  frame: z.number().int(),
  x: z.number(),
  y: z.number(),
})
export const VideoType = z.enum([
  'tactical',
  'panoramic',
  'left',
  'right',
  'goal_left',
  'goal_right',
])

export const endpoints = makeApi([
  {
    method: 'get',
    path: '/boards',
    alias: 'get_boards_boards_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'create_if_not_exists',
        type: 'Query',
        schema: z.boolean().optional(),
      },
    ],
    response: z.array(Board),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'post',
    path: '/boards',
    alias: 'save_board_boards_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: BoardCreate,
      },
    ],
    response: Board,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'put',
    path: '/boards/:board_id',
    alias: 'replace_board_boards__board_id__put',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Board,
      },
      {
        name: 'board_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: Board,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'delete',
    path: '/boards/:board_id',
    alias: 'delete_board_boards__board_id__delete',
    requestFormat: 'json',
    parameters: [
      {
        name: 'board_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({}).partial(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs',
    alias: 'get_clubs_clubs_get',
    requestFormat: 'json',
    response: z.array(Club),
  },
  {
    method: 'post',
    path: '/clubs',
    alias: 'create_club_clubs_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ClubCreate,
      },
    ],
    response: ClubResponse,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'patch',
    path: '/clubs/:club_id',
    alias: 'update_club_endpoint_clubs__club_id__patch',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ClubUpdate,
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: ClubResponse,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/matches',
    alias: 'get_matches_clubs__club_id__matches_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'status',
        type: 'Query',
        schema: z.enum([
          'planned',
          'ready_for_recording',
          'recording',
          'recorded',
          'uploading',
          'uploaded',
          'post_processing',
          'finished',
          'error',
        ]),
      },
      {
        name: 'type',
        type: 'Query',
        schema: z.enum(['eq', 'gte', 'lte', 'ne']).optional().default('eq'),
      },
    ],
    response: z.array(MatchWithImage),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'post',
    path: '/clubs/:club_id/matches',
    alias: 'create_match_clubs__club_id__matches_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: MatchCreate,
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: Match,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/matches/:match_id',
    alias: 'get_match_clubs__club_id__matches__match_id__get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: Match,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'delete',
    path: '/clubs/:club_id/matches/:match_id',
    alias: 'delete_match_clubs__club_id__matches__match_id__delete',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({}).partial(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'patch',
    path: '/clubs/:club_id/matches/:match_id',
    alias: 'update_match_clubs__club_id__matches__match_id__patch',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: MatchUpdate,
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: Match,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/matches/:match_id/announcement',
    alias:
      'get_announcement_image_clubs__club_id__matches__match_id__announcement_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'language',
        type: 'Query',
        schema: z.string(),
      },
      {
        name: 'timezone',
        type: 'Query',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'put',
    path: '/clubs/:club_id/matches/:match_id/archive',
    alias:
      'delete_archive_match_clubs__club_id__matches__match_id__archive_put',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial(),
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({}).partial(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/matches/:match_id/events',
    alias: 'get_match_events_clubs__club_id__matches__match_id__events_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.array(Event),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'post',
    path: '/clubs/:club_id/matches/:match_id/events',
    alias: 'create_match_event_clubs__club_id__matches__match_id__events_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: EventCreate,
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: Event,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'put',
    path: '/clubs/:club_id/matches/:match_id/events/:event_id',
    alias:
      'replace_match_event_clubs__club_id__matches__match_id__events__event_id__put',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Event,
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'event_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: Event,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'delete',
    path: '/clubs/:club_id/matches/:match_id/events/:event_id',
    alias:
      'delete_match_event_clubs__club_id__matches__match_id__events__event_id__delete',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'event_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({}).partial(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/matches/:match_id/review-situations',
    alias:
      'get_correction_situation_clubs__club_id__matches__match_id__review_situations_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'team_no',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'index',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: z.array(ReviewSituation),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/matches/:match_id/share',
    alias: 'get_share_link_clubs__club_id__matches__match_id__share_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.string(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'post',
    path: '/clubs/:club_id/matches/:match_id/tracking',
    alias: 'kickoff_tracking_clubs__club_id__matches__match_id__tracking_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: KickoffTrackingResponse,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/matches/:match_id/tracking/:team_id/assignment',
    alias:
      'get_tracking_assignment_clubs__club_id__matches__match_id__tracking__team_id__assignment_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'team_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: Assignments,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'put',
    path: '/clubs/:club_id/matches/:match_id/tracking/:team_id/assignment',
    alias:
      'update_tracking_assignment_clubs__club_id__matches__match_id__tracking__team_id__assignment_put',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Assignments,
      },
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'team_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: Assignments,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'post',
    path: '/clubs/:club_id/matches/:match_id/tracking/:team_id/assignment',
    alias:
      'create_tracking_assignment_clubs__club_id__matches__match_id__tracking__team_id__assignment_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AssignmentsCreate,
      },
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'team_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: Assignments,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'post',
    path: '/clubs/:club_id/matches/:match_id/tracking/:team_id/process',
    alias:
      'generate_data_clubs__club_id__matches__match_id__tracking__team_id__process_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'team_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/matches/:match_id/tracking/:team_id/stats',
    alias:
      'get_stats_clubs__club_id__matches__match_id__tracking__team_id__stats_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'team_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.array(PlayerStats),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/matches/:match_id/tracking/url',
    alias:
      'get_tracking_url_clubs__club_id__matches__match_id__tracking_url_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ tracking_url: z.string() }).partial(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/matches/:match_id/url',
    alias: 'get_match_url_clubs__club_id__matches__match_id__url_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'video_type',
        type: 'Query',
        schema: z.enum([
          'tactical',
          'panoramic',
          'left',
          'right',
          'goal_left',
          'goal_right',
        ]),
      },
    ],
    response: z.string(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/matches/:match_id/urls',
    alias: 'get_match_urls_clubs__club_id__matches__match_id__urls_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: MatchUrls,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/matches/:match_id/video.m3u8',
    alias:
      'get_match_playlist_clubs__club_id__matches__match_id__video_m3u8_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'token',
        type: 'Query',
        schema: z.string(),
      },
    ],
    response: z.string(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'post',
    path: '/clubs/:club_id/matches/access',
    alias: 'add_access_match_clubs__club_id__matches_access_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.unknown(),
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'share_key',
        type: 'Query',
        schema: z.string(),
      },
    ],
    response: Match,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'post',
    path: '/clubs/:club_id/matches/local',
    alias: 'create_local_match_clubs__club_id__matches_local_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: LocalMatchCreate,
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'delete',
    path: '/clubs/:club_id/matches/local/:match_id/delete-with-events',
    alias:
      'delete_local_match_with_events_clubs__club_id__matches_local__match_id__delete_with_events_delete',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'post',
    path: '/clubs/:club_id/matches/local/urlcheck',
    alias: 'check_video_url_clubs__club_id__matches_local_urlcheck_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.string(),
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({ video_url: z.string() }),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/players',
    alias: 'get_players_clubs__club_id__players_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'team_id',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: z.array(Player),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'put',
    path: '/clubs/:club_id/players',
    alias: 'update_players_clubs__club_id__players_put',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.array(PlayerUpdate),
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.array(Player),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'post',
    path: '/clubs/:club_id/players',
    alias: 'create_player_clubs__club_id__players_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: PlayerCreate,
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: Player,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'put',
    path: '/clubs/:club_id/players/:player_id',
    alias: 'update_player_clubs__club_id__players__player_id__put',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: PlayerUpdate,
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'player_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: Player,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'delete',
    path: '/clubs/:club_id/players/:player_id',
    alias: 'delete_player_clubs__club_id__players__player_id__delete',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'player_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/playlists',
    alias: 'get_playlists_clubs__club_id__playlists_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.array(Playlist),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'post',
    path: '/clubs/:club_id/playlists',
    alias: 'add_new_playlist_clubs__club_id__playlists_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ name: z.string() }),
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: Playlist,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/playlists/:playlist_id',
    alias: 'get_playlist_detail_clubs__club_id__playlists__playlist_id__get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'playlist_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: PlaylistWithEvents,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'delete',
    path: '/clubs/:club_id/playlists/:playlist_id',
    alias: 'delete_playlist_clubs__club_id__playlists__playlist_id__delete',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'playlist_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'patch',
    path: '/clubs/:club_id/playlists/:playlist_id',
    alias: 'update_playlist_clubs__club_id__playlists__playlist_id__patch',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: PlaylistUpdate,
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'playlist_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: Playlist,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/playlists/:playlist_id/clips/:event_id',
    alias:
      'get_clip_clubs__club_id__playlists__playlist_id__clips__event_id__get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'playlist_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'event_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'type',
        type: 'Query',
        schema: z.enum(['panoramic', 'tactical']),
      },
      {
        name: 'cache_break',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'keep_timestamps',
        type: 'Query',
        schema: z.boolean().optional().default(true),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'post',
    path: '/clubs/:club_id/playlists/:playlist_id/events/:event_id/drawing',
    alias:
      'add_drawing_clubs__club_id__playlists__playlist_id__events__event_id__drawing_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DrawingCreate,
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'playlist_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'event_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: Drawing,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'put',
    path: '/clubs/:club_id/playlists/:playlist_id/events/:event_id/drawing/:drawing_id',
    alias:
      'update_drawing_clubs__club_id__playlists__playlist_id__events__event_id__drawing__drawing_id__put',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Drawing,
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'playlist_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'event_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'drawing_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: Drawing,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'delete',
    path: '/clubs/:club_id/playlists/:playlist_id/events/:event_id/drawing/:drawing_id',
    alias:
      'delete_drawing_clubs__club_id__playlists__playlist_id__events__event_id__drawing__drawing_id__delete',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'playlist_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'event_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'drawing_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({}).partial(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'post',
    path: '/clubs/:club_id/playlists/:playlist_id/events/add',
    alias:
      'add_playlist_event_clubs__club_id__playlists__playlist_id__events_add_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ event_id: z.string() }),
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'playlist_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({}).partial(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/playlists/:playlist_id/events/drawings',
    alias:
      'get_drawings_clubs__club_id__playlists__playlist_id__events_drawings_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'playlist_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.array(Drawing),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'post',
    path: '/clubs/:club_id/playlists/:playlist_id/events/remove',
    alias:
      'remove_playlist_event_clubs__club_id__playlists__playlist_id__events_remove_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ event_id: z.string() }),
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'playlist_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({}).partial(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/playlists/views',
    alias: 'get_playlist_views_clubs__club_id__playlists_views_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'search',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'pinned',
        type: 'Query',
        schema: z.boolean().optional(),
      },
    ],
    response: z.array(PlaylistView),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/recording_systems',
    alias: 'get_recording_systems_clubs__club_id__recording_systems_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.array(RecordingSystemOut),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/recording_systems/:recording_system_id/rois',
    alias:
      'get_rois_clubs__club_id__recording_systems__recording_system_id__rois_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'recording_system_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.array(CustomRoiMask),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/teams/:team_id/leagues',
    alias: 'get_leagues_clubs__club_id__teams__team_id__leagues_get',
    description: `Retrieve leagues for a specific team.
Args:
    club_id (str): The ID of the club.
    team_id (str): The ID of the team.
    db (deps.ReplayDb, optional): The database dependency. Defaults to Depends(deps.get_db).
    user (User, optional): The user with required permissions. 
        Defaults to Depends(get_user_with_permission([&quot;coach&quot;, &quot;analyst&quot;, &quot;device_manager&quot;])).
Returns:
    List[League]: A list of League objects representing the leagues found for the team.
Raises:
    HTTPException: If no leagues are found for the team.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'team_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.array(League),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'post',
    path: '/clubs/:club_id/teams/standalone',
    alias: 'create_standalone_team_clubs__club_id__teams_standalone_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: StandaloneTeamCreate,
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: StandaloneTeam,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'post',
    path: '/clubs/:club_id/teams/with-club',
    alias: 'create_team_with_club_clubs__club_id__teams_with_club_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: TeamWithClubCreate,
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: TeamWithClub,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/clubs/:club_id/users',
    alias: 'get_users_clubs__club_id__users_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.array(ClubUser),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'post',
    path: '/clubs/:club_id/users',
    alias: 'create_user_clubs__club_id__users_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UserCreate,
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: UserCreate,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'delete',
    path: '/clubs/:club_id/users/:user_id',
    alias: 'delete_user_clubs__club_id__users__user_id__delete',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'user_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'patch',
    path: '/clubs/:club_id/users/:user_id',
    alias: 'update_user_clubs__club_id__users__user_id__patch',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UserUpdate,
      },
      {
        name: 'club_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'user_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/downloads',
    alias: 'get_downloads_downloads_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'acknowledged',
        type: 'Query',
        schema: z.boolean().optional(),
      },
    ],
    response: z.array(DownloadWithLink),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'post',
    path: '/downloads',
    alias: 'create_download_downloads_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DownloadCreate,
      },
    ],
    response: Download,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'delete',
    path: '/downloads/:id',
    alias: 'delete_download_downloads__id__delete',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'patch',
    path: '/downloads/:id',
    alias: 'update_download_downloads__id__patch',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ acknowledged: z.boolean() }),
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: Download,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/groups',
    alias: 'get_user_groups_groups_get',
    requestFormat: 'json',
    response: z.array(z.string()),
  },
  {
    method: 'get',
    path: '/matches/:match_id/process',
    alias: 'post_processing_match_matches__match_id__process_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'version',
        type: 'Query',
        schema: z.enum(['v1', 'v2', 'v3']).optional().default('v2'),
      },
    ],
    response: z.object({}).partial(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/matches/:match_id/process-stats',
    alias: 'post_processing_match_matches__match_id__process_stats_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'match_id',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'max_frames',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'reset_now',
        type: 'Query',
        schema: z.boolean().optional(),
      },
    ],
    response: z.object({}).partial(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/matches/export/',
    alias: 'export_matches_matches_export__get',
    requestFormat: 'json',
    response: z.object({}).partial(),
  },
  {
    method: 'post',
    path: '/migrate-users',
    alias: 'migrate_users_migrate_users_post',
    requestFormat: 'json',
    response: z.unknown(),
  },
  {
    method: 'post',
    path: '/partner-share',
    alias: 'partner_share_partner_share_post',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ShareRequest,
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/recording_systems/:recording_system_id/device_state/request',
    alias:
      'request_device_state_recording_systems__recording_system_id__device_state_request_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'recording_system_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({}).partial(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/recording_systems/:recording_system_id/generate_masks',
    alias:
      'request_device_generate_masks_recording_systems__recording_system_id__generate_masks_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'recording_system_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({}).partial(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/recording_systems/:recording_system_id/pitch_img',
    alias:
      'get_pitch_image_recording_systems__recording_system_id__pitch_img_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'recording_system_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: PitchImage,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/recording_systems/:recording_system_id/pitch_img/request',
    alias:
      'request_pitch_image_recording_systems__recording_system_id__pitch_img_request_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'recording_system_id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({}).partial(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/recording_systems/admin',
    alias: 'get_recording_systems_admin_recording_systems_admin_get',
    requestFormat: 'json',
    response: z.array(RecordingSystemOutAdmin),
  },
  {
    method: 'get',
    path: '/share-info',
    alias: 'get_share_info_share_info_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'share_key',
        type: 'Query',
        schema: z.string(),
      },
      {
        name: 'token',
        type: 'Query',
        schema: z.string(),
      },
    ],
    response: SharingModel,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/teams/standalone',
    alias: 'get_standalone_teams_teams_standalone_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Query',
        schema: z.string(),
      },
    ],
    response: z.array(StandaloneTeam),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/teams/with-club',
    alias: 'get_teams_with_club_teams_with_club_get',
    requestFormat: 'json',
    parameters: [
      {
        name: 'club_id',
        type: 'Query',
        schema: z.string(),
      },
    ],
    response: z.array(TeamWithClub),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: 'get',
    path: '/users/current_user',
    alias: 'get_user_users_current_user_get',
    requestFormat: 'json',
    response: ReplayUserUpdate,
  },
  {
    method: 'patch',
    path: '/users/current_user',
    alias: 'update_user_users_current_user_patch',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ReplayUserUpdate,
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
])
